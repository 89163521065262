import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _isDarkTheme: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public isDarkTheme = this._isDarkTheme.asObservable();
  constructor(private route: Router) {}
  setDarkTheme(isDarkTheme: boolean): void {
    this._isDarkTheme.next(isDarkTheme);
    this.route.navigate([], {
      queryParams: { dark: isDarkTheme },
      queryParamsHandling: 'merge',
    });
  }
}
